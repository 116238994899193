import React from "react"
import PropTypes from "prop-types"

const Intro = props => {
  return (
    <div className="section">
      <div className="wrap">
        <h2>{props.title}</h2>
        <p dangerouslySetInnerHTML={{ __html: `${props.description}` }}></p>
      </div>
    </div>
  )
}

Intro.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default Intro
