import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { Hero, Intro } from "../../components/StandartPage/index"
import { Services, Steps } from "../../components/Sections/index"
import BlogCard from "../../components/PostCard/PostCardGroup"
import Seo from "../../components/seo"

const DeportAvukatiPage = ({ data: { blog } }) => (
  <Fragment>
    <Seo
      title="Bilişim Avukatı"
      description="Tecrübeli bilişim avukatları ekibimiz, müşterilerin ürünlerini korumalarına, sözleşmelerini müzakere etmelerine ve haklarını savunmalarına yardımcı olma konusunda uzmanlaşmıştır."
    />
    <Hero
      title="Bilişim Avukatı"
      subtitle="Tecrübeli bilişim avukatları ekibimiz, müşterilerin ürünlerini korumalarına, sözleşmelerini müzakere etmelerine ve haklarını savunmalarına yardımcı olma konusunda uzmanlaşmıştır."
    />
    <Intro
      description="
      Tecrübeli BT avukatları ekibimiz, müşterilerin ürünlerini korumalarına, sözleşmelerini müzakere etmelerine ve haklarını savunmalarına yardımcı olma konusunda uzmanlaşmıştır. Uzmanlığımız, tüm BT ve teknoloji hukuku ve uygulamalarını kapsar. Müvekillerimiz özellikle aşağıdakilerden yararlanabilir:
      <br/><br/>
      Devlet kurumu tavsiyesi – bir devlet kurumuna verilerinin bulut hizmetleri aracılığıyla sağlanması konusunda tavsiyelerde bulunabilir ve ayrıca teknoloji sağlayıcılarla gerekli sözleşmelerin hazırlanmasına ve müzakere edilmesine yardımcı olabiliriz.
      <br/><br/>
      Yazılım sağlayıcı sözleşmeleri - yazılım sağlayıcılara çeşitli sektörlerde sözleşme müzakerelerinde yardımcı oluyoruz. Müşterilerimizin çoğu FinTech alanında faaliyet gösteriyor, bu nedenle büyük bankalar ve finansal hizmet sağlayıcılarla düzenli olarak anlaşmalarımız var.
      <br/><br/>
      Son Kullanıcı Lisans Sözleşmeleri – Yazılım hizmet sözleşmelerinin, geliştirme sözleşmelerinin, destek ve bakım sözleşmelerinin, kanal ortağı sözleşmelerinin ve yönetilen hizmet sözleşmelerinin birleştirilmesine yardımcı oluyoruz.
      <br/><br/>
      Sözleşme anlaşmazlıkları – Avukat ekibimiz, özellikle ücretlerin ödenmemesi ve/veya erken fesih ile ilgili olarak BT hukukunun tüm yelpazesini kapsayan sözleşme anlaşmazlıklarını ve davaları ve ayrıca fikri mülkiyet mülkiyeti ile ilgili anlaşmazlıkları güvenle ele alabilir. 
      <br/><br/>
      Veri koruma – Veri koruma avukatlarımız, iş verilerinin ve dijitalleştirilmiş bilgilerin güvenli bir şekilde nasıl korunacağı ve bulut desteği konusunda profesyonel tavsiyeler sunar.
      <br/><br/>
      İnternet hukuku/web sitesi tavsiyesi – BT avukatlarımız, müşterilere web siteleri de dahil olmak üzere çevrimiçi varlıklarını nasıl koruyacakları konusunda talimat verir.
      <br/><br/>
      E-ticaret – şirketiniz bir e-ticaret hizmeti sağlıyorsa, tüketici sözleşmelerine (Bilgilendirme, İptal ve Ek Ücretlendirme) Düzenlemeleri ve Tüketici Hakları Yasası uyum konusunda size uygun şekilde rehberlik edebiliriz."
    />
    <Services />
    <Steps />
    <BlogCard posts={blog.edges} />
  </Fragment>
)

export default DeportAvukatiPage

export const blogQuery = graphql`
  {
    blog: allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            date
            slug
            tags
            title
            description
            Image {
              childImageSharp {
                gatsbyImageData(width: 500)
              }
            }
          }
        }
      }
    }
  }
`
