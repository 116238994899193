import React from "react"
import PropTypes from "prop-types"

const Hero = props => {
  return (
    <div className="section standart-hero">
      <div className="header-top-overlay"></div>
      <div className="nav-height"></div>
      <div className="wrap">
        <div className="sub-wrap">
          <h1>{props.title}</h1>
          <p>{props.subtitle}</p>
        </div>
      </div>
    </div>
  )
}

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
}

export default Hero
